import { combineReducers } from 'redux';

import customers from './customers';
import floors from './floors';
import login from './login';
import configInfo from './configInfo';
import instructions from './instructions';
import invoice from './invoiceSearch';
import products from './products';
import salesmen from './salesmen';
import instructionInfo from './instructionInfo';
import salesmenInfo from './salesmenInfo';
import selectedPos from './selectedPos';
import categories from './categories';
import productsSearchValue from './productsSearch';
import orderType from './orderType';
import initialPosInfo from './initialPosInfo';
import order from './order';
import savedOrders from './fetchSavedOrders';
import todaysOrders from './fetchTodaysOrders';
import isViewAllOrdersSelected from './viewAllOrders';
import isViewTodaysOrdersSelected from './viewTodaysOrders';
import saveForLaterOffline from './saveForLaterOffline';
import isCustomersViewSelected from './customersView';
import previousOrders from './customerPreviousOrders';
import isCustomerPreviousOrdersViewSelected from './customerPreviousOrdersView';
import customer from './customer';
import countries from './countries';
import states from './states';
import paymentView from './paymentView';
import paymentList from './paymentList';
import selectedView from './selectedView';
import kdsOrders from './kdsOrders';
import tableView from './tableView';
import carBrands from './carBrands';
import cars from './cars';
import oilFloors from './oilFloors';
import taxClasses from './taxClasses';
import readings from './fetchCarReadings';
import duplicateBillView from './duplicateBillView';
import isHourlyReportViewSelected from './hourlyReportView';
import report from './fetchHourlyReport';
import inventory from './inventory';
import keyDownEvent from './keyDownEvent';
import smsLog from './fetchSmsLogs';
import isClosingReportViewSelected from './closingReportView';
import closingReport from './fetchClosingReport';
import searchType from './searchType';
import customersPoints from './customersPoints';
import customersSearch from './customersSearch';
import closingReportFilters from './closingReportFilters';
import disableCustomer from './disableCustomer';
import discardProductClicked from './discardProductClick';
import isSyncingInProgressInLiveMode from './syncingInLiveMode';
import selectedOrderLine from './selectedOrderLine';
import debounceTerm from './debounceTerm';
import cancelToken from './cancelToken';
import customersLastOrders from './customersLastOrders';
import shouldResumeSync from './shouldResumeSync';
import customerTopupView from './customerTopupView';
import customerTopupSummary from './fetchCustomerTopupSummary';
import customerTopupDetails from './fetchCustomerTopupDetails';
import vendorExpensesView from './vendorExpensesView';
import vendorExpensesSummary from './fetchVendorExpenses';
import vendorExpensesDetails from './fetchVendorExpensesDetails';
import isQuickPaymentInProgress from './quickPaymentStatus';
import saveForLaterSyncing from './saveForLaterSyncing';
import isViewSyncingOrdersSelected from './viewSyncingOrders'

const appReducer = combineReducers({
  customers,
  floors,
  login,
  configInfo,
  instructions,
  invoice,
  products,
  salesmen,
  instructionInfo,
  salesmenInfo,
  selectedPos,
  categories,
  productsSearchValue,
  orderType,
  initialPosInfo,
  order,
  savedOrders,
  todaysOrders,
  isViewAllOrdersSelected,
  isViewTodaysOrdersSelected,
  saveForLaterOffline,
  isCustomersViewSelected,
  previousOrders,
  customer,
  isCustomerPreviousOrdersViewSelected,
  countries,
  states,
  paymentView,
  paymentList,
  selectedView,
  kdsOrders,
  tableView,
  carBrands,
  cars,
  oilFloors,
  taxClasses,
  readings,
  duplicateBillView,
  isHourlyReportViewSelected,
  report,
  inventory,
  keyDownEvent,
  smsLog,
  isClosingReportViewSelected,
  closingReport,
  searchType,
  customersPoints,
  customersSearch,
  closingReportFilters,
  disableCustomer,
  discardProductClicked,
  isSyncingInProgressInLiveMode,
  selectedOrderLine,
  debounceTerm,
  cancelToken,
  customersLastOrders,
  shouldResumeSync,
  customerTopupView,
  customerTopupSummary,
  customerTopupDetails,
  vendorExpensesView,
  vendorExpensesSummary,
  vendorExpensesDetails,
  isQuickPaymentInProgress,
  saveForLaterSyncing,
  isViewSyncingOrdersSelected
});

export default appReducer;
