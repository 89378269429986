import { message } from 'antd';

import { callApi } from '../../service';
import { GET_CUSTOMERS_POINTS } from '../../types';

const getCustomersPoints = (data, response) => async (dispatch) => {
  // const response = await callApi({
  //   api_type: 'customers_points',
  //   db: data.database,
  //   email: data.email,
  //   password: data.password,
  //   url: data.url,
  // });

  // if (response?.data?.error) {
  //   return message.error(
  //     `${response.data.error.message}, Fetch Customers Points Failed!`,
  //     1
  //   );
  // }
  // if (response?.data?.result && !response.data.result.length) {
  //   return;
  // }

  dispatch({
    type: GET_CUSTOMERS_POINTS,
    customersPoints: response?.result,
  });
};

export { getCustomersPoints };
