import { Button, Input, InputNumber, message, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Clear } from '../../assets/backspace.svg';
import { DRIVETHRU, OIL_REST_HOTEL } from '../../constants';

import './ordersdialpad.scss';

const { Title } = Typography;

const Index = ({
  action,
  display,
  setDisplay,
  handleOk,
  setActionsModal,
  actionsModal,
  type,
  discType,
  setDiscType,
  isAuthenticated,
  setisAuthenticated,
}) => {
  const selectedPos = useSelector((state) => state.selectedPos.selectedPos);
  const configInfo = useSelector((state) => state.configInfo.configInfo);

  const [passInput, setPassInput] = useState('');
  const [isDotPressed, setIsDotPressed] = useState(-1);

  const handleButtonClick = (e) => {
    console.log({ discType });
    // const button = e.target.innerText || 'clear';
    let button;
    if (e.target.innerText === '' || !e.target.innerText) {
      button = 'clear';
    } else {
      button = e.target.innerText;
    }

    console.log({ action: actionsModal?.action });
    switch (button) {
      case 'C':
        setDisplay('');
        setIsDotPressed(-1);
        break;
      case '+10':
        if (
          actionsModal?.action === 'Disc.' &&
          discType &&
          discType === 'percent' &&
          parseFloat(Number(Number(display) + 10).toFixed(2)) > 100
        ) {
          message.error("Discount value can't be greater than 100%");
          return;
        }
        setDisplay(Number(Number(display) + 10).toFixed(2));
        break;
      case '+20':
        if (
          actionsModal?.action === 'Disc.' &&
          discType &&
          discType === 'percent' &&
          parseFloat(Number(Number(display) + 20).toFixed(2)) > 100
        ) {
          message.error("Discount value can't be greater than 100%");
          return;
        }
        setDisplay(Number(Number(display) + 20).toFixed(2));
        break;
      case '+100':
        if (
          actionsModal?.action === 'Disc.' &&
          discType &&
          discType === 'percent' &&
          parseFloat(Number(Number(display) + 100).toFixed(2)) > 100
        ) {
          message.error("Discount value can't be greater than 100%");
          return;
        }
        setDisplay(Number(Number(display) + 100).toFixed(2));
        break;
      case '+/-':
        if (!display || !display?.length || display === 0.0) {
          return;
        }
        if (
          actionsModal?.action === 'Disc.' &&
          discType &&
          discType === 'percent' &&
          parseFloat(Number(-1 * parseFloat(display)).toFixed(2)) < 0
        ) {
          message.error("Discount value can't be negative");
          return;
        }
        setDisplay(Number(-1 * parseFloat(display)).toFixed(2));
        break;
      case 'clear':
        if (isDotPressed === 0) {
          setIsDotPressed(-1);
        }
        if (isDotPressed === 1) {
          const displayArray = display.toString().split('.');
          const splittedString = displayArray[1].split('');
          splittedString[0] = '0';
          displayArray[1] = splittedString.join('');
          console.log({ displayArray });
          setDisplay(Number(displayArray.join('.')).toFixed(2));
          setIsDotPressed(0);
        }
        if (isDotPressed === 2) {
          const displayArray = display.toString().split('.');
          const splittedString = displayArray[1].split('');
          splittedString[1] = '0';
          displayArray[1] = splittedString.join('');
          console.log({ displayArray });
          setDisplay(Number(displayArray.join('.')).toFixed(2));
          setIsDotPressed(1);
        }
        if (isDotPressed === -1) {
          console.log('here');
          const displayArray = display.toString().split('.');
          displayArray[0] = displayArray[0].slice(0, -1);
          setDisplay(Number(displayArray.join('.')).toFixed(2));
        }
        break;
      case '.':
        if (isDotPressed === -1) {
          setIsDotPressed(0);
          if (!display.length) {
            setDisplay('0.00');
          }
        } else {
          return;
        }
        break;
      default:
        if (!String(display).length) {
          if (
            actionsModal?.action === 'Disc.' &&
            discType &&
            discType === 'percent' &&
            parseFloat(
              Number(String(display) + e.target.innerText).toFixed(2)
            ) > 100
          ) {
            message.error("Discount value can't be more than 100%");
            return;
          }
          setDisplay(Number(String(display) + e.target.innerText).toFixed(2));
        } else {
          if (isDotPressed === -1) {
            const displayArray = display.toString().split('.');
            if (
              actionsModal?.action === 'Disc.' &&
              discType &&
              discType === 'percent' &&
              parseFloat(
                Number(
                  displayArray[0] + e.target.innerText + '.' + displayArray[1]
                ).toFixed(2)
              ) > 100
            ) {
              message.error("Discount value can't be more than 100%");
              return;
            }
            setDisplay(
              Number(
                displayArray[0] + e.target.innerText + '.' + displayArray[1]
              ).toFixed(2)
            );
          }
          if (isDotPressed === 0) {
            const displayArray = display.toString().split('.');
            const splittedString = displayArray[1].split('');
            splittedString[0] = e.target.innerText;
            displayArray[1] = splittedString.join('');
            console.log({ displayArray });
            if (
              actionsModal?.action === 'Disc.' &&
              discType &&
              discType === 'percent' &&
              parseFloat(Number(displayArray.join('.')).toFixed(2)) > 100
            ) {
              message.error("Discount value can't be more than 100%");
              return;
            }
            setDisplay(Number(displayArray.join('.')).toFixed(2));
            setIsDotPressed(1);
          }
          if (isDotPressed === 1) {
            const displayArray = display.toString().split('.');
            const splittedString = displayArray[1].split('');
            splittedString[1] = e.target.innerText;
            displayArray[1] = splittedString.join('');
            if (
              actionsModal?.action === 'Disc.' &&
              discType &&
              discType === 'percent' &&
              parseFloat(Number(displayArray.join('.')).toFixed(2)) > 100
            ) {
              message.error("Discount value can't be more than 100%");
              return;
            }
            setDisplay(Number(displayArray.join('.')).toFixed(2));
            setIsDotPressed(2);
          }
          if (isDotPressed === 2) {
            message.error('Only two decimal numbers are allowed!', 1);
          }
        }

        break;
    }
  };

  const getButtonsText = () => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      switch (action) {
        case 'Qty':
          return ['Disc.'];
        case 'Disc.':
          return ['Qty'];
        default:
          return ['Disc.'];
      }
    } else {
      switch (action) {
        case 'Qty':
          return ['Price', 'Disc.'];
        case 'Price':
          return ['Qty', 'Disc.'];
        case 'Disc.':
          return ['Qty', 'Price'];
        default:
          return ['Qty', 'Disc.'];
      }
    }
  };

  return (
    <div>
      {((action === 'Disc.' && configInfo?.ask_password_discount) ||
        (configInfo?.ask_password_order_discount &&
          type === 'overall-discount')) &&
      !isAuthenticated ? (
        <div className="discount-pass-modal">
          <Input
            placeholder={'Please Enter Pin'}
            value={passInput}
            type="password"
            autoComplete="new-password"
            onChange={(e) => setPassInput(e.target.value)}
          />
          <div className="btn-wrapper">
            <Button
              type="primary"
              onClick={() => {
                if (type === 'overall-discount') {
                  if (passInput !== configInfo?.password_order_discount) {
                    message.error('Invalid Pin');
                  } else {
                    setPassInput('');
                    setisAuthenticated(true);
                  }
                } else {
                  if (passInput !== configInfo?.password_discount) {
                    message.error('Invalid Pin');
                  } else {
                    setPassInput('');
                    setisAuthenticated(true);
                  }
                }
              }}
            >
              Okay
            </Button>
          </div>
        </div>
      ) : (
        <div className="orders-dialpad">
          <Title level={3} className="heading">
            {action === 'Disc.' && type === 'overall-discount'
              ? 'Global Discount'
              : actionsModal.action}
          </Title>
          {action === 'Disc.' && (
            <div className="toggle-buttons">
              <Button
                type={discType === 'percent' && 'primary'}
                onClick={(e) => {
                  setDiscType('percent');
                }}
              >
                Percent
              </Button>
              <Button
                type={discType === 'fixed' && 'primary'}
                onClick={(e) => {
                  setDiscType('fixed');
                }}
              >
                Fixed
              </Button>
            </div>
          )}
          <Input
            disabled={true}
            className="display"
            placeholder="0.00"
            style={{ textAlign: 'right' }}
            autoFocus={true}
            keyboard={true}
            value={display}
            onChange={(e) => {
              const value = e.target.value;
              if (value.split('.').length - 1 > 1) {
                return;
              } else {
                setDisplay(value);
              }
            }}
          />
          {/* <div className="display">{display}</div> */}
          <div className="pad">
            <button
              onClick={handleButtonClick}
              style={{
                borderTopLeftRadius: '0.5rem',
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              1
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              2
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              3
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                borderTopRightRadius: '0.5rem',
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              +10
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              4
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              5
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              6
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              {action === 'Disc.' && selectedPos?.pos_type === DRIVETHRU
                ? '+100'
                : '+20'}
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              7
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              8
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              9
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              +/-
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                borderBottomLeftRadius: '0.5rem',
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              C
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              0
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              .
            </button>
            <button
              onClick={handleButtonClick}
              style={{
                borderBottomRightRadius: '0.5rem',
                backgroundColor: 'transparent',
                border: '1px solid #ccc',
                cursor: 'pointer',
              }}
            >
              <Clear />
            </button>
          </div>
          <div
            className={`action-buttons ${
              type === 'overall-discount' && 'single-btn'
            } ${
              selectedPos?.pos_type === OIL_REST_HOTEL ? 'oil-actions' : ''
            } `}
          >
            {type !== 'overall-discount' && (
              <Button
                onClick={() => {
                  setActionsModal({
                    status: true,
                    action: getButtonsText()[0],
                  });
                  handleOk(false);
                }}
              >
                {getButtonsText()[0]}
              </Button>
            )}
            {type !== 'overall-discount' && (
              <Button
                onClick={() => {
                  setActionsModal({
                    status: true,
                    action: getButtonsText()[1],
                  });
                  handleOk(false);
                }}
              >
                {getButtonsText()[1]}
              </Button>
            )}
            <Button
              type="primary"
              onClick={() => {
                setisAuthenticated(false);
                handleOk();
              }}
            >
              Done
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
