import React from 'react';
import { Spin } from 'antd';

const loaderStyles = {
  width: '100vw',
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
  zIndex: 9999
};

const Index = ({ style, tip }) => {
  return (
    <div style={style || loaderStyles}>
      <Spin size="large" tip={`${tip || ''}`} style={{ zIndex: 9999 }} />
    </div>
  );
};

export default Index;
