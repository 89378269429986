import { FETCH_CUSTOMER_TOPUP_SUMMARY } from '../types';

const initialState = {
  customerTopupSummary: [],
};

const customerTopupSummary = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TOPUP_SUMMARY:
      return { ...state, customerTopupSummary: action.customerTopupSummary };
    default:
      return state;
  }
};

export default customerTopupSummary;
