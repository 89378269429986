import { SET_CUSTOMER_TOPUP_VIEW } from '../types';

const initialState = {
  status: false,
};

const customerTopupView = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_TOPUP_VIEW:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default customerTopupView;
