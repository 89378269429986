import { message } from 'antd';
import moment from 'moment';
import localforage from 'localforage';
import { getDataFromIndexedDB, setDataInIndexedDB } from '../../../utils';

import { callApi } from '../../service';
import {
  GET_CUSTOMERS_PREVIOUS_ORDERS,
  SET_CUSTOMERS_PREVIOUS_ORDERS,
} from '../../types';

const getCustomerPreviousOrders =
  (data, emptyPreviousOrders = false) =>
    async (dispatch) => {
      if (emptyPreviousOrders && !data?.loadSameDay) {
        dispatch({
          type: SET_CUSTOMERS_PREVIOUS_ORDERS,
          previousOrders: undefined,
        });
        return;
      }

      const previouslySavedHistory = await getDataFromIndexedDB(
        'orders-history',
        localforage
      );
      if (previouslySavedHistory?.length && !data?.loadSameDay) {
        dispatch({
          type: GET_CUSTOMERS_PREVIOUS_ORDERS,
          previousOrders: previouslySavedHistory || [],
        });
        return;
      }

      let concatHistory = [];

      const getPreviousOrders = async (last_item_id, dataT) => {
        const response = await callApi({
          api_type: 'order_history',
          db: dataT.database,
          email: dataT.email,
          password: dataT.password,
          url: dataT.url,
          req_arg: {
            last_item_id: last_item_id,
            id: dataT.id,
            pos_type: dataT.pos_type,
            partner_id: dataT?.partner_id ? dataT?.partner_id : null,
            // date_from: '2022-07-02',
            // date_to: '2022-07-09',
            date_from: dataT?.loadSameDay
              ? moment().format('YYYY-MM-DD')
              : moment(moment().subtract(6, 'd')).format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD'),
          },
        });

        if (response?.data?.error) {
          return message.error(
            `${response.data.error.message}, Fetch Previous Orders Failed!`,
            1
          );
        }
        if (response?.data?.result?.data && !response.data.result?.data.length) {
          message.info('Oops, Previous Orders Not Found!', 1);
        }

        concatHistory = [...concatHistory, ...response?.data?.result?.data];
        if (response?.data?.result?.last_item_id !== Boolean && response?.data?.result?.last_item_id) {
          getPreviousOrders(response?.data?.result?.last_item_id, dataT);
        } else {
          await setDataInIndexedDB(
            'orders-history',
            [...response?.data?.result?.data],
            localforage
          );

          const latestDataFromIndexedDB = await getDataFromIndexedDB(
            'orders-history',
            localforage
          );

          dispatch({
            type: GET_CUSTOMERS_PREVIOUS_ORDERS,
            previousOrders: latestDataFromIndexedDB || [],
          });
        }
      }

      await getPreviousOrders(0, data);
    };

export { getCustomerPreviousOrders };
