import React, { createContext, useState, useRef, useEffect, memo } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';
import { useSelector } from 'react-redux';
import { message } from 'antd';

const SocketContext = createContext();
const socket = io('https://nodelive.visiomate.com', {
  autoConnect: false,
});
// const socket = io('https://nodetest.visiomate.com', {
//   autoConnect: false,
// });
// const socket = io('http://localhost:5000', {
//   autoConnect: false,
// });
// const socket = io('https://0716-103-204-32-96.ngrok-free.app', {
//   autoConnect: false,
// });
const ContextProvider = ({ children }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState('');
  const [call, setCall] = useState({});
  const [me, setMe] = useState('');
  const configInfo = useSelector((state) => state.configInfo.configInfo);
  const user = useSelector((state) => state.login.user);

  const [isMicMuted, setIsMicMuted] = useState(false);

  const userVideo = useRef();
  const callerConnectionRef = useRef();
  const receiverConnectionRef = useRef();
  const userStream = useRef();

  useEffect(() => {
    socket.emit('agent-muted', {
      isMuted: isMicMuted,
    });
  }, [isMicMuted]);

  const toggleMicState = () => {
    stream?.getAudioTracks()?.forEach((track) => {
      let newValue = !track.enabled;
      track.enabled = newValue;
      setIsMicMuted(newValue);
    });
  };

  const makeConnection = (user, configInfo, screenType) => {
    socket.io.opts.query = {
      user: JSON.stringify(user),
      sessionId: configInfo?.session_id,
      screenType: screenType,
    };

    socket.connect();

    socket.on('disconnect', () => {
      if (screenType === 'live-customer') {
        socket.off('callAccepted');
        // setCall({});
        // setCallAccepted(false);
        // receiverConnectionRef.current.destroy();
        receiverConnectionRef?.current?.destroy();
        // document.getElementById('customer-side').srcObject = undefined;
      }
    });
  };

  useEffect(() => {
    if (configInfo && user) {
      socket.on('connection-success', (id) => {
        setMe(id);
      });

      socket.on('callUser', ({ from, name: callerName, signal }) => {
        setCall({ isReceivingCall: true, from, name: callerName, signal });
      });
      socket.on('callEnded', () => {
        setCallEnded(false);
        setCall({});
        setCallAccepted(false);
        receiverConnectionRef?.current?.destroy();
      });

      window.addEventListener('beforeunload', () => {
        socket.emit('reset-customer-screen', {
          sessionId: configInfo?.session_id,
        });
        socket.disconnect();
      });
    }
  }, [configInfo, user]);

  const setMyStream = async () => {
    let currentStream;
    try {
      currentStream = await navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
      currentStream.getAudioTracks().forEach((track) => {
        setIsMicMuted(track.enabled);
      })
      setStream(currentStream);
      return true;
    } catch (error) {
      message.error('Media Device not accessible...', 2);
      return false;
    }
    // .then((currentStream) => {
    //   setStream(currentStream);
    //   currentStream.getAudioTracks().forEach((track) => {
    //     setIsMicMuted(track.enabled);
    //   });
    // })
    // .catch((err) => {
    //   message.error('Media Device not accessible...', 2);
    // });
    // if(currentStream.)


  };

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('answerCall', { signal: data, to: call.from });
    });

    peer.on('stream', (currentStream) => {
      document.getElementById('customer-side').srcObject = currentStream;
    });

    peer.on('close', () => {
      socket.off('callAccepted');
      setCall({});
      setCallAccepted(false);
      // receiverConnectionRef.current.destroy();
      peer.destroy();
      // document.getElementById('customer-side').srcObject = undefined;
    });

    // peer.on('close', () => {
    //   socket.off('callAccepted');
    //   userVideo.current = undefined;
    //   setCallEnded(false);
    //   setCall({});
    //   setCallAccepted(false);
    //   connectionRef?.current?.destroy();


    peer.signal(call.signal);

    receiverConnectionRef.current = peer;
  };

  const callUser = (id, sessionId, cb) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });
    peer.on('signal', (data) => {
      socket.emit('callUser', {
        userToCall: id,
        signalData: data,
        from: me,
        name,
        sessionId,
      });
    });
    peer.on('stream', (currentStream) => {
      userStream.current = currentStream;
      document.getElementById('web-cam-video').srcObject = currentStream;
    });
    peer.on('close', () => {
      socket.off('callAccepted');
    });
    socket.on('callAccepted', (signal) => {
      peer.signal(signal);
      setCallAccepted(true);
      setCallEnded(false);
    });
    if (cb) {
      cb(callAccepted);
    }
    // socket.on('prev-sock-removed', (data) => {

    // });

    // peer.on('close', () => {
    //   socket.off('callAccepted');
    //   userStream.current = undefined;
    //   userVideo.current = undefined;
    // });

    callerConnectionRef.current = peer;
  };

  const leaveCall = (sessionId) => {
    setCallEnded(true);
    setCallAccepted(false);
    // userVideo.current = undefined;
    // socket.emit('call_ended', { sessionId: configInfo?.session_id });
    setCall({});
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      })
    }
    callerConnectionRef?.current?.destroy();

  };


  return (
    <SocketContext.Provider
      value={{
        makeConnection,
        socket,
        call,
        callAccepted,
        userVideo,
        stream,
        name,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall,
        setMyStream,
        userStream,
        toggleMicState,
        isMicMuted,

      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
