import { SET_SAVE_FOR_LATER } from "../../types";

const setViewAllOrders =
  (isViewAllOrdersSelected, showDbOrders) => (dispatch) => {
    dispatch({
      type: SET_SAVE_FOR_LATER,
      isViewAllOrdersSelected,
      showDbOrders,
    });
  };

export { setViewAllOrders };
