import { useEffect, useState, useRef, useContext } from 'react';
import {
  Empty,
  Modal,
  Typography,
  Button,
  Input,
  message,
} from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import {
  ExclamationCircleOutlined,
  FormOutlined,
  InfoCircleOutlined,
  StarOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import { DateTime } from 'luxon';

import { setOrder } from '../../store/actions/set/order';
import { saveOpenOrder } from '../../store/actions/set/openOrder';
import { setOilFloors } from '../../store/actions/set/oilFloors';
import { setKeyDownEvent } from '../../store/actions/set/keyDownEvent';
import { discardOrder } from '../../store/actions/set/discardOrder';
import { setDiscardProductClicked } from '../../store/actions/set/discardProductClick';

import { generateOrder } from '../OrdersActions';
import ErrorBoundary from '../ErrorBoundary';

import {
  calculateOrder,
  generateId,
  getDataFromIndexedDB,
  getDiscoundProduct,
} from '../../utils';
import { DRIVETHRU, OIL_REST_HOTEL } from '../../constants';

import './ordersqueue.scss';
import localforage from 'localforage';
import { setSelectedOrderLine } from '../../store/actions/set/selectedOrderLine';
import { SocketContext } from '../../Contexts/SocketContext';

const { Title } = Typography;

export const getTotal = (prev, cur) =>
  prev + parseFloat(cur.lst_price) * cur.quantity;

export const getTotalPrice = (prev, cur) => {
  return prev + parseFloat(cur.fixed_lstprice || cur?.lst_price) * cur.quantity;
};

export const getTotalLineDisc = (prev, cur) => {
  return (
    prev +
    parseFloat(cur?.custom_discount_value ? cur?.custom_discount_value : 0) *
    cur?.quantity
  );
};

export const getTotalEarnedPoints = (prev, cur) =>
  prev + parseFloat(cur.reward_points) * cur.quantity;

export const getTotalRedeemedPoints = (prev, cur) =>
  prev + parseFloat(cur.redeem_points) * cur.quantity;

export const getRedeemPoints = (ordersLinesList) =>
  ordersLinesList
    .filter((line) => line.is_redeemed && line.reward_points)
    .reduce(getTotalRedeemedPoints, 0)
    ?.toFixed(0);

export const getEarnedPoints = (ordersLinesList) =>
  ordersLinesList
    .filter((line) => line.reward_points)
    .reduce(getTotalEarnedPoints, 0)
    ?.toFixed(0);

const Index = () => {
  const {
    callAccepted,
    callEnded,
    userStream,
  } = useContext(SocketContext);
  const dispatch = useDispatch();
  const yesButtonRef = useRef();
  const order = useSelector((state) => state.order?.order);
  const user = useSelector((state) => state.login.user);
  const orderType = useSelector((state) => state.orderType.orderType);
  const configInfo = useSelector((state) => state.configInfo.configInfo);
  const customer = useSelector((state) => state.customer.customer);
  const selectedPos = useSelector((state) => state.selectedPos?.selectedPos);
  const tableView = useSelector((state) => state.tableView.tableView);
  const floors = useSelector((state) => state.floors?.floors);
  const oilFloors = useSelector((state) => state.oilFloors?.oilFloors);
  const keyDownEvent = useSelector((state) => state.keyDownEvent?.status);

  const [ordersLinesList, setOrdersLinesList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [oilSelectedTableOrder, setOilSelectedTableOrder] = useState(undefined);
  const [newLineTemporaryId, setNewLineTemporaryId] = useState(undefined);

  const handleOk = async () => {
    const allProducts = await getDataFromIndexedDB('products', localforage);
    let discountProd = getDiscoundProduct(allProducts, configInfo);
    let selectedOrder = document
      .querySelector('.orders-queue')
      ?.getAttribute('order');

    selectedOrder = JSON.parse(selectedOrder);

    if (customer?.points_earned) {
      const activeCustomerTotalEarnedPoints =
        (customer?.points_earned ? Number(customer?.points_earned) : 0) +
        (getEarnedPoints(order?.lines)
          ? Number(getEarnedPoints(order?.lines))
          : 0);

      const totalRedeemPoints = getRedeemPoints(order?.lines)
        ? Number(getRedeemPoints(order?.lines))
        : 0;

      const selectedOrderLine = selectedOrder?.lines?.find(
        (line) => line.isSelected
      );

      if (selectedOrderLine.is_redeemed) {
        // Do nothing
      } else if (activeCustomerTotalEarnedPoints <= totalRedeemPoints) {
        message.info(
          "Can't remove this product as it is associated with redeemed product!",
          1
        );
        setIsModalVisible(false);
        return;
      }
    }

    const getLines = () => {
      let removeLine = false;
      let updatedLines = [];
      updatedLines = selectedOrder.lines?.map((line) => {
        if (line.isSelected) {
          if (Number(line.quantity) > 1) {
            return { ...line, quantity: Number(line.quantity) - 1 };
          } else {
            removeLine = { ...line };
          }
        }

        return line;
      });

      if (removeLine) {
        let selectedLineIndex = order?.lines?.findIndex(
          (line) => line.isSelected
        );
        let selectedLineReached = false;
        updatedLines = updatedLines = (
          removeLine.parent_int_categ || removeLine.is_ingredient
            ? updatedLines.filter((line) => !line.isSelected)
            : updatedLines.filter((line, index) => {
              if (line.isSelected) {
                selectedLineReached = true;
              }
              if (
                selectedLineReached &&
                !(line.parent_int_categ || line.is_ingredient) &&
                index > selectedLineIndex
              ) {
                selectedLineReached = false;
              }
              if (
                selectedLineReached &&
                (line.parent_int_categ || line.is_ingredient) &&
                index > selectedLineIndex
              ) {
                return false;
              }
              if (!line.isSelected) {
                return true;
              }
            })
        ).map((line, index) =>
          index === selectedLineIndex - 1 ? { ...line, isSelected: true } : line
        );
      }

      return updatedLines;
    };

    const creationDate = DateTime.now().toUTC();
    let updatedOrder = {
      ...selectedOrder,
      lines: getLines(),
    };
    const data = {
      data: generateOrder(
        {
          ...updatedOrder,
          orderType,
          order_status: 'live',
        },
        configInfo,
        creationDate,
        customer
      ),
      ...user,
    };

    if (
      window.navigator.onLine &&
      configInfo?.live_order_sync &&
      selectedPos.pos_type === DRIVETHRU
    ) {
      const orderName = await saveOpenOrder(data);
      if (orderName) {
        if (
          !(
            configInfo?.order_prefix &&
            typeof updatedOrder?.name !== 'boolean' &&
            updatedOrder?.name?.includes(configInfo.order_prefix)
          )
        ) {
          updatedOrder = { ...updatedOrder, name: orderName };
        }
      }
    }

    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      const newFloors = (oilFloors?.length ? oilFloors : floors)?.map((f) => {
        if (f.id === tableView.floor_id) {
          f.tables?.forEach((t) => {
            if (t.id === tableView.table_id && t.order?.lines.length) {
              t.order = {
                ...t.order,
                lines: getLines(),
              };
              if (
                t.order.lines.length === 1 &&
                t.order.lines[0].id === discountProd.id
              ) {
                t.order = {
                  ...t.order,
                  lines: [],
                  amount_tax: undefined,
                };
                t.order.global_discount_value = undefined;
              } else {
                t.order = {
                  ...t.order,
                  amount_tax: t.order.global_tax_value
                    ? Number(
                      Number(
                        Number(
                          t.order.lines
                            .filter((oL) =>
                              allProducts.find(
                                (prod) =>
                                  prod.id === oL.id &&
                                  prod.id !== discountProd.id &&
                                  prod.id !==
                                  configInfo?.fbr_service_fee_product
                                    ?.product_id
                              )
                            )
                            ?.reduce(getTotal, 0)
                            .toFixed(2)
                        )
                      ) * Number(Number(t.order.global_tax_value / 100))
                    ).toFixed(2)
                    : 0,
                  lines: calculateOrder(
                    allProducts,
                    t.order.lines,
                    configInfo?.global_discount_default_item?.product_id,
                    t.order.global_discount_type
                      ? t.order.global_discount_type
                      : false,
                    t.order.global_discount_value
                      ? t.order.global_discount_value
                      : false,
                    t.order.global_tax_value ? t.order.global_tax_value : false,
                    configInfo
                  ),
                };
              }
            }
          });
        }

        return f;
      });

      dispatch(setOilFloors([...newFloors]));
    } else {
      if (updatedOrder?.lines?.length) {
        dispatch(
          setOrder({
            ...updatedOrder,
            name: updatedOrder?.name,
            amount_tax: updatedOrder.global_tax_value
              ? Number(
                Number(
                  Number(
                    updatedOrder.lines
                      .filter((oL) =>
                        allProducts.find(
                          (prod) =>
                            prod.id === oL.id &&
                            prod.id !== discountProd?.id &&
                            prod.id !==
                            configInfo?.fbr_service_fee_product?.product_id
                        )
                      )
                      ?.reduce(getTotal, 0)
                      .toFixed(2)
                  )
                ) * Number(Number(updatedOrder.global_tax_value / 100))
              ).toFixed(2)
              : 0,
            lines: calculateOrder(
              allProducts,
              updatedOrder.lines,
              configInfo?.global_discount_default_item?.product_id,
              updatedOrder.global_discount_type
                ? updatedOrder.global_discount_type
                : false,
              updatedOrder.global_discount_value
                ? updatedOrder.global_discount_value
                : false,
              updatedOrder.global_tax_value
                ? updatedOrder.global_tax_value
                : false,
              configInfo
            ),
          })
        );
      } else {
        if (window.navigator.onLine) {
          const response = await discardOrder({
            ...user,
            orderName: updatedOrder.name,
          });
          if (response === 'success') {
            dispatch(
              setOrder({
                name: generateId(),
                lines: [],
                salesmanId: updatedOrder.workerId,
                workerId: updatedOrder.workerId,
                orderType: updatedOrder.orderType,
                payment: undefined,
              })
            );
          }
        } else {
          dispatch(
            setOrder({
              name: generateId(),
              lines: [],
              salesmanId: updatedOrder.workerId,
              workerId: updatedOrder.workerId,
              orderType: updatedOrder.orderType,
              payment: undefined,
            })
          );
        }
        // dispatch(
        //   setOrder({
        //     ...updatedOrder,
        //     name: generateId(),
        //   })
        // );
      }
      if (
        window.navigator.onLine &&
        updatedOrder?.lines?.length &&
        selectedPos?.pos_type === DRIVETHRU &&
        configInfo?.live_order_sync
      ) {
        setTimeout(() => {
          dispatch(setDiscardProductClicked(true));
        }, 500);
      }
    }

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBackspaceClick = () => {
    setIsModalVisible(true);
    yesButtonRef?.current?.focus();
  };

  const handleKeyDownEvents = async (e) => {
    let selectedOrder = document
      .querySelector('.orders-queue')
      ?.getAttribute('order');

    let floors = document
      .querySelector('.orders-queue')
      ?.getAttribute('floors');

    let tableView = document
      .querySelector('.orders-queue')
      ?.getAttribute('tableview');

    let isModalVisible = document
      .querySelector('.orders-queue')
      ?.getAttribute('ismodalvisible');

    if (floors) {
      floors = JSON.parse(floors);
    }

    if (tableView) {
      tableView = JSON.parse(tableView);
    }

    if (isModalVisible) {
      isModalVisible = JSON.parse(isModalVisible);
    }

    const focusedInputs = document.querySelectorAll('input:focus');
    const focusedTextArea = document.querySelectorAll('textarea:focus');
    const removeLineModal = document.querySelector('.delete-order-line-modal');

    if (
      (e?.code === 'Backspace' || e?.key === 'Backspace') &&
      selectedOrder &&
      !isModalVisible &&
      !focusedInputs.length &&
      !focusedTextArea.length
    ) {
      if (JSON.parse(selectedOrder)?.lines?.find((line) => line.isSelected)) {
        handleBackspaceClick();
      }
    }

    const checkRemoveLineModalStatus = removeLineModal
      ? ![...removeLineModal?.classList].includes('true')
      : true;

    if (
      checkRemoveLineModalStatus &&
      selectedOrder &&
      !focusedTextArea.length &&
      JSON.parse(selectedOrder).lines?.find(
        (line) => line.isSelected && line.isQuantityEditable
      ) &&
      (e?.key === 'Escape' || e?.key === 'Enter')
    ) {
      await handleOnBlur(floors, tableView);
    }

    if (
      checkRemoveLineModalStatus &&
      selectedOrder &&
      JSON.parse(selectedOrder).lines?.find(
        (line) => line.isSelected && !line.isQuantityEditable
      ) &&
      !focusedInputs.length &&
      !focusedTextArea.length &&
      e?.key === 'Enter'
    ) {
      if (selectedPos?.pos_type === OIL_REST_HOTEL) {
        const newFloors = floors?.map((f) => {
          if (f.id === tableView.floor_id) {
            f.tables?.forEach((t) => {
              if (t.id === tableView.table_id && t.order?.lines.length) {
                t.order = {
                  ...t.order,
                  lines: t.order?.lines.map((oL) =>
                    oL.isSelected
                      ? { ...oL, isQuantityEditable: true }
                      : { ...oL, isQuantityEditable: false }
                  ),
                };
              }
            });
          }

          return f;
        });

        dispatch(setOilFloors([...newFloors]));
      }
    }
  };

  const handleOnBlur = async (floors, tableView) => {
    const allProducts = await getDataFromIndexedDB('products', localforage);
    let discountProd = getDiscoundProduct(allProducts, configInfo);

    const newQuantity = document.querySelector('.edit-quantity').value;

    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      const newFloors = floors?.map((f) => {
        if (f.id === tableView.floor_id) {
          f.tables?.forEach((t) => {
            if (t.id === tableView.table_id && t.order?.lines.length) {
              t.order = {
                ...t.order,
                lines: t.order?.lines.map((oL) => ({
                  ...oL,
                  isQuantityEditable: false,
                  quantity: oL?.isSelected
                    ? Number(newQuantity) <= 0
                      ? oL?.quantity
                      : Number(newQuantity)
                    : oL?.quantity,
                })),
              };
              if (t.order) {
                t.order = {
                  ...t.order,
                  amount_tax: t.order.global_tax_value
                    ? Number(
                      Number(
                        Number(
                          t.order.lines
                            .filter((oL) =>
                              allProducts.find(
                                (prod) =>
                                  prod.id === oL.id &&
                                  prod.id !== discountProd.id &&
                                  prod.id !==
                                  configInfo?.fbr_service_fee_product
                                    ?.product_id
                              )
                            )
                            ?.reduce(getTotal, 0)
                            .toFixed(2)
                        )
                      ) * Number(Number(t.order.global_tax_value / 100))
                    ).toFixed(2)
                    : 0,
                  lines: calculateOrder(
                    allProducts,
                    t.order.lines,
                    configInfo?.global_discount_default_item?.product_id,
                    t.order.global_discount_type
                      ? t.order.global_discount_type
                      : false,
                    t.order.global_discount_value
                      ? t.order.global_discount_value
                      : false,
                    t.order.global_tax_value ? t.order.global_tax_value : false,
                    configInfo
                  ),
                };
              }
            }
          });
        }

        return f;
      });

      dispatch(setOilFloors([...newFloors]));
    }
  };

  const handleKeyUpEvents = (e) => {
    let selectedOrder = document
      .querySelector('.orders-queue')
      ?.getAttribute('order');

    let isModalVisible = document
      .querySelector('.orders-queue')
      ?.getAttribute('ismodalvisible');

    if (isModalVisible) {
      isModalVisible = JSON.parse(isModalVisible);
    }

    const focusedInputs = document.querySelectorAll('input:focus');
    const focusedTextArea = document.querySelectorAll('textarea:focus');

    if (
      (e?.code === 'Backspace' || e?.key === 'Backspace') &&
      selectedOrder &&
      !isModalVisible &&
      !focusedInputs.length &&
      !focusedTextArea.length
    ) {
      if (JSON.parse(selectedOrder)?.lines?.find((line) => line.isSelected)) {
        handleBackspaceClick();
      }
    }
  };

  // handleKeyDown and handleKeyUp
  useEffect(() => {
    if (!keyDownEvent) {
      document.addEventListener('keydown', handleKeyDownEvents);
    }
    document.addEventListener('keyup', handleKeyUpEvents);
    if (userStream?.current) {
      document.getElementById('web-cam-video').srcObject = userStream?.current;
    }
    return () => {
      document.removeEventListener('keyup', () => '');
      document.removeEventListener('keydown', () => '');
      dispatch(setKeyDownEvent(true));
    };
  }, []);

  useEffect(() => {
    if (order?.lines?.length) {
      if (order.lines.length > ordersLinesList?.length) {
        const line = order.lines[order.lines.length - 1];
        setNewLineTemporaryId(line?.temporaryId);
      }
      setOrdersLinesList(order.lines);
    } else {
      setOrdersLinesList([]);
    }
  }, [order.lines]);

  useEffect(() => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      let selectedTableOrder;
      oilFloors?.find((f) => {
        if (f.id === tableView.floor_id) {
          selectedTableOrder = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.order;
        }
      });
      setOilSelectedTableOrder(selectedTableOrder);
      if (selectedTableOrder?.lines?.length) {
        if (selectedTableOrder.lines.length > ordersLinesList?.length) {
          const line =
            selectedTableOrder.lines[selectedTableOrder.lines.length - 1];
          setNewLineTemporaryId(line?.temporaryId);
        }
        setOrdersLinesList(selectedTableOrder?.lines);
      } else {
        setOrdersLinesList([]);
      }
    }
  }, [oilFloors]);

  useEffect(() => {
    if (newLineTemporaryId) {
      const newOrderLineItemElement =
        document.getElementById(newLineTemporaryId);
      newOrderLineItemElement?.scrollIntoView();
      setNewLineTemporaryId(undefined);
    }
  }, [newLineTemporaryId]);

  const handleOrderLineClick = (temporaryId) => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      const newFloors = (oilFloors?.length ? oilFloors : floors)?.map((f) => {
        if (f.id === tableView.floor_id) {
          f.tables?.forEach((t) => {
            if (t.id === tableView.table_id && t.order?.lines.length) {
              t.order = {
                ...t.order,
                lines: t.order?.lines.map((oL) =>
                  oL.temporaryId === temporaryId
                    ? { ...oL, isSelected: true }
                    : { ...oL, isSelected: false }
                ),
              };
            }
          });
        }

        return f;
      });

      dispatch(setOilFloors([...newFloors]));
    } else {
      dispatch(
        setOrder({
          ...order,
          lines: order.lines?.map(
            (oL) => {
              if (oL.temporaryId === temporaryId) {
                dispatch(setSelectedOrderLine({ ...oL }));
                return { ...oL, isSelected: true };
              } else {
                return { ...oL, isSelected: false };
              }
            }
            // oL.temporaryId === temporaryId
            //   ? { ...oL, isSelected: true }
            //   : { ...oL, isSelected: false }
          ),
        })
      );
    }
  };

  const getOrdersLines = () =>
    ordersLinesList
      .filter(
        (line) =>
          line.id !== configInfo?.global_discount_default_item?.product_id
      )
      .map((orderLine) => (
        <div
          key={generateId()}
          className={`order ${orderLine.isSelected ? 'selected' : ''} ${orderLine?.parent_int_categ?.length || orderLine.is_ingredient
            ? 'ingredient'
            : ''
            }${orderLine?.is_redeemed ? ' is-redeemed' : ''}`}
          onClick={() => handleOrderLineClick(orderLine.temporaryId)}
          id={orderLine.temporaryId}
        >
          <div className="title-and-description">
            {orderLine?.pos_categ_id?.toString() === '24' ? (
              <>
                <span style={{ marginRight: '0.5rem' }}>
                  <PlusCircleFilled
                    twoToneColor="white"
                    style={{ color: '#5A189A' }}
                  />
                </span>
                <span
                  className="title"
                  style={{ color: orderLine?.product_color || 'inherit' }}
                >
                  {orderLine.display_name || orderLine.name}
                </span>
              </>
            ) : (
              <div
                className="title"
                style={{ color: orderLine?.product_color || 'inherit' }}
              >
                {orderLine.display_name || orderLine.name}
              </div>
            )}

            {!orderLine.distinct_product && (
              <div className="description">
                <span className="quantity">
                  {orderLine?.isQuantityEditable ? (
                    <Input
                      type="number"
                      style={{ width: '60px' }}
                      defaultValue={orderLine.quantity}
                      autoFocus
                      className="edit-quantity"
                    />
                  ) : (
                    orderLine.quantity
                  )}
                </span>{' '}
                Unit at{' '}
                <span className="quantity">
                  {orderLine.lst_price?.toFixed(2)}
                </span>
              </div>
            )}
            {!!(orderLine.reward_points && configInfo.show_reward_line) && (
              <div className="description">
                <StarOutlined style={{ marginRight: '0.2dvw' }} />
                <span className="quantity">{orderLine.reward_points}</span>
              </div>
            )}
            {!!orderLine.custom_discount && (
              <div className="description">
                Product Discount:{' '}
                <span className="quantity">
                  {orderLine.custom_discount}{' '}
                  {orderLine?.custom_discount_type === 'percent'
                    ? '%'
                    : selectedPos?.currency?.symbol}
                  {orderLine?.custom_discount_type === 'percent' &&
                    ` (${Number(orderLine?.custom_discount_value)?.toFixed(
                      2
                    )} ${selectedPos?.currency?.symbol})`}
                </span>
              </div>
            )}
            {orderLine.note && (
              <div className="orderline-note">
                <span className="note-icon">
                  <FormOutlined />
                </span>{' '}
                <span className="note-text">{orderLine.note}</span>
              </div>
            )}
            {!!orderLine.tax_value && (
              <div className="orderline-tax">
                Tax at{' '}
                {oilSelectedTableOrder?.global_tax_value ||
                  order?.global_tax_value}
                % ={' '}
                <span className="tax-text">
                  {/* {selectedPos?.currency?.symbol}{' '} */}
                  {Number(orderLine?.tax_value).toFixed(2)}
                </span>
              </div>
            )}
            {!!orderLine.react_instructions_list?.length && (
              <div className="react_instructions_list">
                <span className="instructions">
                  <InfoCircleOutlined />{' '}
                </span>
                {orderLine.react_instructions_list.map((list, i) => (
                  <span className="quantity" key={i + Math.random()}>
                    {list.message_content}
                    {i !== orderLine.react_instructions_list.length - 1
                      ? ','
                      : ''}{' '}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="total">
            {/* {selectedPos?.currency?.symbol}{' '} */}
            {Number(orderLine.quantity * orderLine.lst_price)?.toFixed(2)}
          </div>
        </div>
      ));

  const showDiscountLine = () => {
    const queued = ordersLinesList.find(
      (oL) => oL.id === configInfo?.global_discount_default_item?.product_id
    );
    if (queued) {
      return (
        <div
          key={generateId()}
          className={`order${queued.isSelected ? ' selected' : ''}`}
          onClick={() => handleOrderLineClick(queued.temporaryId)}
        >
          <div className="title-and-description">
            <div className="title">{queued.display_name || queued.name}</div>
            <div className="description">
              <span className="quantity">{queued.quantity}</span> Unit at{' '}
              <span className="quantity">{queued.lst_price?.toFixed(2)}</span>
            </div>
            {!!queued.reward_points && (
              <div className="description">
                <StarOutlined style={{ marginRight: '0.2rem' }} />
                <span className="quantity">{queued.reward_points}</span>
              </div>
            )}
            {!!queued.custom_discount && (
              <div className="description">
                Discount:{' '}
                <span className="quantity">{queued.custom_discount} %</span>
              </div>
            )}
            {queued.note && (
              <div className="orderline-note">
                <span className="note-icon">
                  <FormOutlined />
                </span>{' '}
                <span className="note-text">{queued.note}</span>
              </div>
            )}
            {!!queued.react_instructions_list?.length && (
              <div className="react_instructions_list">
                <span className="instructions">
                  <InfoCircleOutlined />{' '}
                </span>
                {queued.react_instructions_list.map((list, i) => (
                  <span className="quantity" key={i + Math.random()}>
                    {list.message_content}
                    {i !== queued.react_instructions_list.length - 1
                      ? ','
                      : ''}{' '}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="total">
            {/* {selectedPos?.currency?.symbol}{' '} */}
            {Number(queued.quantity * queued.lst_price)?.toFixed(2)}
          </div>
        </div>
      );
    }
  };
  ordersLinesList.filter(
    (line) => line.id !== configInfo?.global_discount_default_item?.product_id
  );

  const getOrder = () => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      if (oilSelectedTableOrder) {
        return JSON.stringify(oilSelectedTableOrder);
      }

      return oilSelectedTableOrder;
    }

    if (order) {
      return JSON.stringify(order);
    }

    return order;
  };

  // const getSelectedTableOrder = () => {
  //   if (selectedPos?.pos_type === OIL_REST_HOTEL) {
  //     let selectedTableOrder;
  //     (oilFloors?.length ? oilFloors : floors)?.find((f) => {
  //       if (f.id === tableView.floor_id) {
  //         selectedTableOrder = f.tables.find(
  //           (t) => t.id === tableView.table_id
  //         )?.order;
  //       }
  //     });

  //     return selectedTableOrder;
  //   }
  // };

  const getActiveOrder = () => {
    if (selectedPos?.pos_type === OIL_REST_HOTEL) {
      let selectedTableOrder;
      (oilFloors?.length ? oilFloors : floors)?.find((f) => {
        if (f.id === tableView.floor_id) {
          selectedTableOrder = f.tables.find(
            (t) => t.id === tableView.table_id
          )?.order;
        }
      });

      return selectedTableOrder;
    }

    return order;
  };

  const getOrderSummary = () => {
    let showEarnedPoints = true;
    let showEarnedPointsBalance = true;
    let showPayable = true;
    let showProductDiscount = true;
    let showRedeemPoints = true;
    let showTax = true;
    let showTotalPrice = true;

    if (configInfo?.order_summary_list?.length) {
      const orderSummaryList = configInfo?.order_summary_list;

      if (!orderSummaryList?.find((oS) => oS.name === 'Earned Points')) {
        showEarnedPoints = false;
      }
      if (
        !orderSummaryList?.find((oS) => oS.name === 'Earned Points Balance')
      ) {
        showEarnedPointsBalance = false;
      }
      if (!orderSummaryList?.find((oS) => oS.name === 'Payable')) {
        showPayable = false;
      }
      if (!orderSummaryList?.find((oS) => oS.name === 'Product Discount')) {
        showProductDiscount = false;
      }
      if (!orderSummaryList?.find((oS) => oS.name === 'Redeem Points')) {
        showRedeemPoints = false;
      }
      if (!orderSummaryList?.find((oS) => oS.name === 'Tax')) {
        showTax = false;
      }
      if (!orderSummaryList?.find((oS) => oS.name === 'Total Price')) {
        showTotalPrice = false;
      }
    }

    return (
      <>
        {selectedPos.pos_type === DRIVETHRU && (
          <>
            {showRedeemPoints && (
              <div className="total-tax">
                Redeem Points:{' '}
                <span>{getRedeemPoints(ordersLinesList) || 0}</span>
              </div>
            )}

            {showEarnedPoints && (
              <div className="total-tax">
                Earned Points:{' '}
                <span>{getEarnedPoints(ordersLinesList) || 0}</span>
              </div>
            )}
            {showEarnedPointsBalance && (
              <div className="total-tax">
                Earned Points Balance:{' '}
                <span>
                  {(customer?.points_earned
                    ? Number(customer?.points_earned)
                    : 0) +
                    (getEarnedPoints(ordersLinesList)
                      ? Number(getEarnedPoints(ordersLinesList))
                      : 0) -
                    (getRedeemPoints(ordersLinesList)
                      ? Number(getRedeemPoints(ordersLinesList))
                      : 0)}
                </span>
              </div>
            )}
          </>
        )}
        {showTotalPrice && (
          <div className="total-price">
            Total Price ({selectedPos?.currency?.symbol}):{' '}
            <span>
              {Number(ordersLinesList.reduce(getTotalPrice, 0))?.toFixed(2)}
            </span>
          </div>
        )}
        {configInfo?.global_discount && (
          <div className="total-price">
            Bill Discount ({selectedPos?.currency?.symbol}):{' '}
            <span>
              {Number(
                ordersLinesList.find(
                  (l) =>
                    (l.display_name || l.name)?.toLowerCase() === 'discount'
                )?.lst_price || 0
              )?.toFixed(2)}
            </span>
          </div>
        )}
        {showProductDiscount && (
          <div className="total-discount">
            Product Discount ({selectedPos?.currency?.symbol}):{' '}
            <span>
              {Number(
                ordersLinesList?.reduce(getTotalLineDisc, 0)?.toFixed(2)
              ) || 0}
            </span>
          </div>
        )}
        {showTax && (
          <div className="total-tax">
            {selectedPos?.pos_type === OIL_REST_HOTEL ? 'Sales Tax' : 'Tax'} (
            {Number(oilSelectedTableOrder?.global_tax_value) ||
              Number(order?.global_tax_value) ||
              0}
            %):{' '}
            {/* <span>
                    {Number(oilSelectedTableOrder?.global_tax_value) || 0}
                  </span> */}
            <span>
              {/* {selectedPos?.currency?.symbol}{' '} */}
              {Number(oilSelectedTableOrder?.amount_tax) ||
                Number(order?.amount_tax) ||
                0}
            </span>
          </div>
        )}
        {showPayable && (
          <div className="payable">
            Payable ({selectedPos?.currency?.symbol}):{' '}
            <span>
              {Number(ordersLinesList.reduce(getTotal, 0)).toFixed(2)}
              {/* {oilSelectedTableOrder?.amount_tax || order?.amount_tax
                ? Number(
                    Number(ordersLinesList.reduce(getTotal, 0)) +
                      (Number(oilSelectedTableOrder?.amount_tax) ||
                        Number(order?.amount_tax))
                  ).toFixed(2)
                : Number(ordersLinesList.reduce(getTotal, 0)).toFixed(2)} */}
            </span>
          </div>
        )}
      </>
    );
  };


  return (
    <ErrorBoundary>
      <div
        className={`orders-queue${!ordersLinesList.length ? ' center' : ''}`}
        style={{
          height: `calc(100% - ${configInfo.enlarge_action_button ? '15.3dvw' : '145px'
            }`,
        }}
        order={getOrder()}
        floors={JSON.stringify(oilFloors?.length ? oilFloors : floors)}
        tableview={JSON.stringify(tableView)}
        ismodalvisible={JSON.stringify(isModalVisible)}
      >
        {ordersLinesList.length ? (
          <PerfectScrollbar
            style={{
              position: 'relative',
              // height: `calc(100vh - calc(${
              //   configInfo.enlarge_action_button ? 355 : 255
              // }px + 1rem))`,
              height: 'calc(100dvh - 23.6dvw)',
              scrollBehavior: 'smooth',
            }}
          >
            <PerfectScrollbar
              style={{
                // height: `calc(100% - ${
                //   (configInfo?.order_summary_list?.length || 0) * 24 + 2
                // }px)`,
                height: 'calc(100% - 11dvw)',
              }}
            >
              {getActiveOrder()
                ?.name?.toLowerCase()
                ?.includes(configInfo.order_prefix?.toLowerCase()) &&
                getActiveOrder()?.order_status && (
                  <div className="order-name">
                    Order# {getActiveOrder()?.name}
                  </div>
                )}
              <div>
                {getOrdersLines()}
                {showDiscountLine()}
              </div>
            </PerfectScrollbar>
            <div className="total-price-wrapper drive-thru-only">
              {getOrderSummary()}
            </div>
          </PerfectScrollbar>
        ) : (
          <Empty description="No Order" />
        )}
      </div>
      <Modal
        title={false}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className={`delete-order-line-modal ${isModalVisible}`}
      >
        <div className="delete-order-line-modal-content-wrapper">
          <ExclamationCircleOutlined />
          <Title level={4}>
            Are you sure you want to delete this orderLine item ?
          </Title>
        </div>
        <div className="button-wrapper">
          <Button danger onClick={handleCancel}>
            No
          </Button>
          <Button danger type="primary" ref={yesButtonRef} onClick={handleOk}>
            Yes
          </Button>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default Index;
