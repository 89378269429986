import { message } from 'antd';

import { callApi } from '../../service';

const validateOrder = async (data) => {
  const response = await callApi({
    api_type: 'payment_validate',
    db: data.database,
    email: data.email,
    password: data.password,
    url: data.url,
    req_arg: {
      pos_type: data.pos_type,
      data: [{ data: data.data }],
    },
  });

  if (response?.data?.error) {
    message.error(`${response.data.error.message}, Validate Order Failed!`, 1);
    return;
  }

  if (
    typeof response?.data?.result === 'string' &&
    response?.data?.result?.toLowerCase() === 'already validate'
  ) {
    message.error('Order Sync failed, please punch the order again !', 1);
    return;
  }

  if (
    typeof response.data?.result === 'string' &&
    response.data?.result?.toLowerCase() ===
      'Session closed please login again'.toLowerCase()
  ) {
    message.error(response.data?.result, 1);
    return;
  }

  message.success('Validate Order Successful!', 1);

  return response?.data?.result;
};

export { validateOrder };
