import { SET_QUICK_PAYMENT_STATUS } from '../types';

const initialState = {
  status: false,
};

const isQuickPaymentInProgress = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUICK_PAYMENT_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
};

export default isQuickPaymentInProgress;
