import { SET_VIEW_SYNCING_ORDERS } from "../types";

const initialState = {
  isViewSyncingOrdersSelected: false,
};

const isViewSyncingOrdersSelected = (state = initialState, action) => {
  switch (action.type) {
    case SET_VIEW_SYNCING_ORDERS:
      return {
        ...state,
        isViewSyncingOrdersSelected: action.isViewSyncingOrdersSelected,
      };
    default:
      return state;
  }
};

export default isViewSyncingOrdersSelected;
