import { SET_VENDOR_EXPENSES_VIEW } from '../types';

const initialState = {
  status: false,
};

const vendorExpensesView = (state = initialState, action) => {
  switch (action.type) {
    case SET_VENDOR_EXPENSES_VIEW:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default vendorExpensesView;
