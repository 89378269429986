import { FETCH_VENDOR_EXPENSES_SUMMARY } from '../types';

const initialState = {
  vendorExpensesSummary: [],
};

const vendorExpensesSummary = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_EXPENSES_SUMMARY:
      return { ...state, vendorExpensesSummary: action.vendorExpensesSummary };
    default:
      return state;
  }
};

export default vendorExpensesSummary;
