import { FETCH_CUSTOMER_TOPUP_DETAILS } from '../types';

const initialState = {
  customerTopupDetails: [],
};

const customerTopupDetails = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_TOPUP_DETAILS:
      return { ...state, customerTopupDetails: action.customerTopupDetails };
    default:
      return state;
  }
};

export default customerTopupDetails;
