import axios from 'axios';
import axiosRetry from 'axios-retry';

import { STATIC_END_POINT, IMPORTANT_INFO_URL } from './urls';

const callApi = async ({
  db,
  email,
  password,
  api_type,
  req_arg,
  url,
  cancelSource,
}) => {
  axiosRetry(axios, {
    retries: 2,
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return !error.response;
    },
  });

  if (cancelSource) {
    return axios({
      method: 'post',
      url: !api_type ? IMPORTANT_INFO_URL : url + STATIC_END_POINT,
      cancelToken: cancelSource.token,
      data: {
        jsonrpc: '2.0',
        params: {
          db,
          email,
          password,
          api_type,
          req_arg,
        },
      },
    });
  } else {
    return axios.post(!api_type ? IMPORTANT_INFO_URL : url + STATIC_END_POINT, {
      // cancelToken: cancelSource.token,
      jsonrpc: '2.0',
      params: {
        db,
        email,
        password,
        api_type,
        req_arg,
      },
    });
  }
};

export { callApi };
