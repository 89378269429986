import { FETCH_VENDOR_EXPENSES_DETAILS } from '../types';

const initialState = {
  vendorExpensesDetails: [],
};

const vendorExpensesDetails = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VENDOR_EXPENSES_DETAILS:
      return { ...state, vendorExpensesDetails: action.vendorExpensesDetails };
    default:
      return state;
  }
};

export default vendorExpensesDetails;
