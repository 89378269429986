import { callApi } from '../../service';

const updateCustomerPoints = async (data) => {
  let response = await callApi({
    api_type: 'update_customer_points',
    db: data.database,
    email: data.email,
    password: data.password,
    url: data.url,
    req_arg: {
      id: data.id,
      updated_points: data.pointsEarned,
    },
  });

  return response?.data?.result;
};

export { updateCustomerPoints };
