import { SET_SAVE_FOR_LATER_SYNCING } from "../types";

const initialState = {
  orders: [],
};

const saveForLaterSyncing = (state = initialState, action) => {


  switch (action.type) {
    case SET_SAVE_FOR_LATER_SYNCING:
      console.log({ action })
      let orders;
      if (
        state.orders.length &&
        state.orders.find((o) => o.data.name === action.order?.data?.name)
      ) {
        console.log("if 1")
        if (action.deleteOrder) {
          console.log("if 2")
          orders = state.orders.filter((o) => o.data.name !== action.order?.data?.name);
        } else {
          console.log("if 3")
          console.log({ action: action.order, state: state.orders })
          orders = state.orders.map((o) =>
            o.data.name === action.order?.data?.name
              ? { ...o, data: { ...o.data, lines: [...action.order?.data?.lines] } }
              : o
          );
        }
      } else {
        console.log("if 4")
        orders = [...state.orders, action.order];
      }
      return { ...state, orders };
    default:
      return state;
  }
};

export default saveForLaterSyncing;
