import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Loader from './components/Loader';

import './global.scss';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ContextProvider } from './Contexts/SocketContext';

const Home = lazy(() => import('./pages/Home'));
const SelectShop = lazy(() => import('./pages/SelectShop'));
const Pos = lazy(() => import('./pages/Pos'));
const Kds = lazy(() => import('./pages/Kds'));
const SelectPosOrKds = lazy(() => import('./pages/SelectPosOrKds'));
const CustomerScreen = lazy(() =>
  import('./pages/CustomerScreen/CustomerScreen')
);
const Signup = lazy(() => import('./pages/Signup'))
const HowToPair = lazy(() => import('./pages/HowToPair'));
const LiveKds = lazy(() => import('./pages/CustomerScreen/LiveKDS'));
const P404 = lazy(() => import('./pages/404'));
const PaymentGateway = lazy(() => import('./pages/PaymentGateway'))

function App() {
  return (
    <Router>
      <ContextProvider>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/select-pos-or-kds" component={SelectPosOrKds} />
            <Route exact path="/select-shop" component={SelectShop} />
            <Route exact path="/pos" component={Pos} />
            <Route exact path="/kds" component={Kds} />
            <Route exact path="/customer-screen" component={CustomerScreen} />
            <Route exact path="/live-kds" component={LiveKds} />
            <Route exact path="/how-to-pair" component={HowToPair} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/pay" component={PaymentGateway} />
            <Route path="*" component={P404} />
          </Switch>
        </Suspense>
      </ContextProvider>
    </Router>
  );
}

export default App;
